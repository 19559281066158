var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-factory-order-create" }, [
    _c("div", { staticClass: "list-box" }, [
      _c("div", { staticClass: "input-item" }, [
        _c("div", { staticClass: "item-title" }, [_vm._v("*1.设备编号")]),
        _c(
          "div",
          { staticClass: "item-input" },
          [
            _c("Field", {
              attrs: {
                label: "",
                placeholder: "请输入设备编号",
                "input-align": "left",
                "right-icon": "scan",
                maxlength: "8",
                type: "number"
              },
              on: {
                "click-right-icon": function($event) {
                  return _vm.onClickIcon(1)
                }
              },
              model: {
                value: _vm.uniqueId,
                callback: function($$v) {
                  _vm.uniqueId = $$v
                },
                expression: "uniqueId"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "input-item" }, [
        _c("div", { staticClass: "item-title" }, [_vm._v("*2.主板编码")]),
        _c(
          "div",
          { staticClass: "item-input" },
          [
            _c("Field", {
              attrs: {
                label: "",
                placeholder: "请输入主板编码",
                "input-align": "left",
                "right-icon": "scan",
                maxlength: "13",
                type: "string"
              },
              on: {
                "click-right-icon": function($event) {
                  return _vm.onClickIcon(2)
                }
              },
              model: {
                value: _vm.barCode,
                callback: function($$v) {
                  _vm.barCode = $$v
                },
                expression: "barCode"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "page-footer" }, [
      _c("div", { staticClass: "footer-btn" }, [_vm._v("取 消")]),
      _vm.uniqueId && _vm.uniqueId.length == 8
        ? _c(
            "div",
            {
              staticClass: "footer-btn pass",
              on: { click: _vm.onclickCreateBtn }
            },
            [_vm._v("确认创建")]
          )
        : _c("div", { staticClass: "footer-btn" }, [_vm._v("确认创建")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }