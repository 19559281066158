require("core-js/modules/es.array.concat");

require("core-js/modules/es.function.name");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.split");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/toast/style", "vant/es/toast", "vant/es/dialog/style", "vant/es/dialog", "vant/es/field/style", "vant/es/field"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/toast/style"), require("vant/es/toast"), require("vant/es/dialog/style"), require("vant/es/dialog"), require("vant/es/field/style"), require("vant/es/field"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.toast, global.style, global.dialog, global.style, global.field);
    global.createOrder = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _toast, _style2, _dialog, _style3, _field) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _toast = _interopRequireDefault(_toast);
  _dialog = _interopRequireDefault(_dialog);
  _field = _interopRequireDefault(_field);
  var _default = {
    name: "DeviceCreateOrder",
    data: function data() {
      return {
        uniqueId: '',
        barCode: ''
      };
    },
    components: {
      Field: _field.default
    },
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      vm.name = name;

      if (!name) {
        vm.$router.replace({
          path: "/factory/login"
        });
        return;
      }

      vm.weixinInit();
    },
    methods: {
      weixinInit: function weixinInit() {
        var vm = this;
        vm.$http({
          type: "get",
          url: "https://m.yudada.com/jssdk/configuration?url=".concat(location.href)
        }).then(function (res) {
          var _res$data = res.data,
              appId = _res$data.appId,
              nonceStr = _res$data.nonceStr,
              signature = _res$data.signature,
              timestamp = _res$data.timestamp;
          window.wx.config({
            debug: false,
            appId: appId,
            timestamp: timestamp,
            nonceStr: nonceStr,
            signature: signature,
            jsApiList: ["scanQRCode"]
          });
        });
      },
      // 1：机身编码，2：主板码
      onClickIcon: function onClickIcon(type) {
        var vm = this;
        var scanType = [];
        scanType.push(type == 1 ? 'qrCode' : 'barCode');
        window.wx.scanQRCode({
          needResult: 1,
          scanType: scanType,
          success: function success(res) {
            if (type == 2) {
              alert(res.resultStr);

              if (!(res.resultStr && res.resultStr.length >= 10)) {
                _dialog.default.confirm({
                  title: "提示",
                  message: "非设备主板码~",
                  confirmButtonColor: "#32BE32",
                  showCancelButton: false
                });
              } else {
                vm.barCode = res.resultStr.split(',').length > 1 ? res.resultStr.split(',')[1] : res.resultStr;
              }

              return;
            }

            var result = res.resultStr && res.resultStr.split('deviceId=')[1];

            if (!result) {
              _dialog.default.confirm({
                title: "提示",
                message: "非设备编号~",
                confirmButtonColor: "#32BE32",
                showCancelButton: false
              });

              return;
            }

            vm.$http({
              type: "get",
              url: "".concat(vm.$config.base.BASE_URL, "admin/iotTool/product/device/").concat(result, "/isNone"),
              except: true
            }).then(function (res) {
              var code = res.code,
                  data = res.data,
                  message = res.message;

              if (code != 1 || !data.length) {
                _toast.default.fail({
                  message: message || data.length ? '服务器繁忙~' : '设备不存在',
                  forbidClick: false,
                  overlay: true,
                  duration: 2000
                });

                return;
              }

              vm.uniqueId = data[0].unique_id;
            }).catch(function (err) {
              console.log(err);
            });
          }
        });
      },
      onclickCreateBtn: function onclickCreateBtn() {
        var vm = this;
        vm.tt = _toast.default.loading({
          message: "加载中...",
          forbidClick: true,
          overlay: true,
          duration: 0
        });
        vm.$http({
          type: "post",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "returnFac/ruiy"),
          data: {
            password: vm.$config.base.password,
            operator: vm.name,
            mainboardId: vm.barCode,
            uniqueId: vm.uniqueId
          },
          except: true
        }).then(function (res) {
          var code = res.code,
              message = res.message,
              data = res.data;
          vm.tt.clear();

          if (code == 1) {
            _toast.default.success({
              message: '返厂单创建成功',
              forbidClick: true,
              overlay: true,
              duration: 2000
            });

            setTimeout(function () {
              vm.$router.push({
                path: "/factory/quality/history/".concat(data.uniqueId, "?id=").concat(data.id)
              });
            }, 2000);
          } else {
            _toast.default.fail({
              message: message,
              forbidClick: true,
              overlay: true,
              duration: 2000
            });
          }
        }).catch(function () {
          vm.tt.clear();
        });
      }
    }
  };
  _exports.default = _default;
});